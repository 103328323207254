import React, { useState } from "react"
import { Field, FieldProps } from "formik"
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates"
import { it as localeIt, enGB as localeGb, de as localeDe, fr as localeFr } from "date-fns/locale"
import { PageContext } from "ExtendiApp"
import { FormGroup, Label, Input, FormFeedback } from "src/ui"
import { LocaleContext } from "src/components/layout"
import useTranslations from "src/components/useTranslations"
import { FormFieldName, FormState, isRequiredField } from "./utils"
import { formatDateToShow } from "src/utils/date"

interface Props {
  nameStart: FormFieldName
  nameEnd: FormFieldName
  label: string
  minDate?: Date
  maxDate?: Date
}

const getLocale = (locale: PageContext["locale"]) => {
  switch (locale) {
    case "de":
      return localeDe
    case "it":
      return localeIt
    case "fr":
      return localeFr
    default:
      return localeGb
  }
}

export const FieldDateRange: React.FC<Props> = ({ nameStart, nameEnd, label, minDate, maxDate }) => {
  const { locale: pageLanguage } = React.useContext(LocaleContext)
  const localeFileToUse = getLocale(pageLanguage)

  const isRequired = isRequiredField(nameStart)

  const [inputStartDateTouched, setInputStartDateTouched] = useState(false)

  return (
    <Field name={nameStart}>
      {({ form: { setFieldValue, values }, meta }: FieldProps<Date, FormState>) => {
        const hasError = Boolean(meta.touched && meta.error)

        return (
          <DateRangePicker
            startDate={values[nameStart]}
            endDate={values[nameEnd]}
            onStartDateChange={(newDate: Date) => {
              setFieldValue(nameStart, newDate)
              setInputStartDateTouched(true)
            }}
            onEndDateChange={(newDate: Date) => {
              setFieldValue(nameEnd, newDate)
              setInputStartDateTouched(true)
            }}
            locale={localeFileToUse}
            minimumDate={minDate}
            maximumDate={maxDate}
          >
            {({ startDateInputProps, endDateInputProps, focus }) => {
              return (
                <FormGroup>
                  <Label for="from">
                    {label} {isRequired ? " *" : ""}
                  </Label>

                  <div className="d-flex align-items-center ">
                    <Input
                      {...startDateInputProps}
                      ref={undefined}
                      id="from"
                      className={"bg-white" + (focus === START_DATE ? " border-bottom border-primary -focused" : "")}
                      placeholder={useTranslations("arrival")}
                      autoComplete="off"
                      value={
                        !inputStartDateTouched ? undefined : formatDateToShow(startDateInputProps.value, pageLanguage)
                      }
                      required
                    />
                    {startDateInputProps.value ? <div className="px-2">/</div> : null}
                    <Input
                      {...endDateInputProps}
                      ref={undefined}
                      id="to"
                      className={"bg-white" + (focus === END_DATE ? " border-bottom border-primary -focused" : "")}
                      placeholder={useTranslations("departure")}
                      value={formatDateToShow(endDateInputProps.value, pageLanguage)}
                      autoComplete="off"
                      required
                    />
                  </div>

                  {hasError ? <FormFeedback className="d-block">{meta.error}</FormFeedback> : null}
                </FormGroup>
              )
            }}
          </DateRangePicker>
        )
      }}
    </Field>
  )
}
