import { openingDate } from "src/utils/date"

type RequestType = "offer" | "booking"

export interface FormState {
  firstName: string
  lastName: string
  address: string
  postalCode: string
  city: string
  province: string
  email: string
  numberOfAdults: string
  numberOfChildren: string
  ageChildren: string[]
  arrivalDate?: Date
  departureDate?: Date
  accommodation: string
  treatment: string
  how_do_you_know_us: string
  extra_notes: string
  requestType?: RequestType
  privacyAccepted: boolean
}

export type FormFieldName = keyof FormState

export const formInitialState: FormState = {
  firstName: "",
  lastName: "",
  address: "",
  postalCode: "",
  city: "",
  province: "",
  email: "",
  numberOfAdults: "",
  numberOfChildren: "",
  ageChildren: [],
  arrivalDate: openingDate,
  departureDate: undefined,
  accommodation: "",
  treatment: "",
  how_do_you_know_us: "",
  extra_notes: "",
  requestType: undefined,
  privacyAccepted: false,
}

export const requiredFieldsArray: Array<FormFieldName> = [
  "firstName",
  "lastName",
  "email",
  "numberOfAdults",
  "departureDate",
  "arrivalDate",
  "accommodation",
  "treatment",
  "privacyAccepted",
]

export const isRequiredField = (field: FormFieldName) => requiredFieldsArray.includes(field)

export const validateForm = (values: FormState, requiredErrorMessage: string, invalidFormatErrorMessage: string) => {
  const requiredEmptyFields = Object.keys(values)
    // removing all fields with a value set
    .filter(key => values[key] === undefined || values[key] === false || values[key] === "")
    // getting all required fields
    .filter(isRequiredField)

  const initialErrorsObj: Partial<FormState> = {}

  const errors: Partial<FormState> = requiredEmptyFields.reduce((prev, curr) => {
    return {
      ...prev,
      [curr]: requiredErrorMessage,
    }
  }, initialErrorsObj)

  // extra validation on email format
  if (!values.email) {
    errors.email = requiredErrorMessage
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = invalidFormatErrorMessage
  }

  return errors
}
