import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { Header } from "src/components/Header"
import { getFluidImageSrc } from "src/utils/images"
import SEO from "src/components/SEO"
import { EnquiryForm } from "src/components/EnquiryForm"

const ContactPage: React.FC<any> = ({ data: { mdx } }) => {
  if (!mdx.frontmatter || !mdx.frontmatter.title) {
    return <div>No content has been created for this page</div>
  }

  const { title: pageTitle, headerImage } = mdx.frontmatter

  return (
    <Fragment>
      <SEO title={pageTitle} />

      {/* HEADER */}
      <Header backgroundImageUrl={getFluidImageSrc(headerImage) || ""} />

      <EnquiryForm title={pageTitle} />
    </Fragment>
  )
}

export default ContactPage

export const query = graphql`
  query ContactPage($locale: String!, $title: String!) {
    mdx(frontmatter: { title: { eq: $title } }, fields: { locale: { eq: $locale } }) {
      frontmatter {
        title

        headerImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1900) {
              ...GatsbyImageSharpFluid
              srcWebp
            }
          }
        }
      }
    }
  }
`
