export const accommodationOptions = [
  {
    value: "single_room",
    label: "single_room",
  },
  {
    value: "double_room",
    label: "double_room",
  },
  {
    value: "triple_room",
    label: "triple_room",
  },
  {
    value: "quadruple_room",
    label: "quadruple_room",
  },
  {
    value: "apartment_type_A",
    label: "apartment_type_A",
  },
  {
    value: "apartment_type_B",
    label: "apartment_type_B",
  },
]

export const treatmentOptions = [
  {
    value: "full_board",
    label: "full_board",
  },
  {
    value: "half_board",
    label: "half_board",
  },
  {
    value: "take_away_meals",
    label: "take_away_meals",
  },
  {
    value: "b_b",
    label: "b_b",
  },
  {
    value: "without_pension",
    label: "without_pension",
  },
]

export const howOptions = [
  {
    value: "already_guest",
    label: "already_guest",
  },
  {
    value: "from_friends",
    label: "from_friends",
  },
  {
    value: "from_research",
    label: "from_research",
  },
  {
    value: "another_website",
    label: "another_website",
  },
  {
    value: "newspaper",
    label: "newspaper",
  },
]
