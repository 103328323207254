import React, { useState } from "react"
import { Formik, Form, FieldArray } from "formik"
import { FormGroup, Label, Input, Container, Col, Row, Button, UncontrolledCollapse, Alert, FormFeedback } from "src/ui"
import useTranslations from "src/components/useTranslations"
import { FieldText } from "./FieldText"
import { formInitialState, validateForm, FormFieldName } from "./utils"
import { accommodationOptions, howOptions, treatmentOptions } from "./optionsForSelects"
import { FieldDateRange } from "./FieldDateRange"
import { openingDate, closingDate } from "src/utils/date"
import { sendTagManagerEvent } from "src/utils/googleTagManager"

interface Props {
  title: string
}

export const EnquiryForm: React.FC<Props> = ({ title }) => {
  const yourData = useTranslations("your_data")
  const yourHoliday = useTranslations("your_holiday")
  const extraInfo = useTranslations("extra_info")
  const requestType = useTranslations("request_type")
  const offer = useTranslations("offer")
  const booking = useTranslations("booking")
  const privacyTop = useTranslations("privacy_top_line")
  const privacyBottom = useTranslations("privacy_bottom_line")
  const submitText = useTranslations("submit_button_text")

  const requiredErrorMessage = useTranslations("required_field")
  const invalidFormatErrorMessage = useTranslations("invalid_format")

  const submitOkMessage = useTranslations("request_sent_thankyou")
  const submitKoMessage = useTranslations("submit_error_messagge")

  const [formSuccessMessage, setFormSuccessMessage] = useState<string | null>()
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>()

  return (
    <section className="section section-default">
      <Container>
        <Row className="justify-content-center">
          <Col sm={{ size: 12 }}>
            <h1 className="text-warning section-title">{title} </h1>

            {formSuccessMessage ? (
              <Alert color="success">{formSuccessMessage}</Alert>
            ) : (
              <Formik
                initialValues={formInitialState}
                validate={values => validateForm(values, requiredErrorMessage, invalidFormatErrorMessage)}
                onSubmit={(values, { setSubmitting }) => {
                  const arrivalDate = values.arrivalDate ? new Date(values.arrivalDate).toLocaleDateString("it") : ""
                  const departureDate = values.departureDate
                    ? new Date(values.departureDate).toLocaleDateString("it")
                    : ""

                  const handleError = () => {
                    setFormErrorMessage(submitKoMessage)
                    setSubmitting(false)
                  }
                  // If you want to load env variables with gatsby client side you should add GATSBY_ before env variables
                  fetch(process.env["GATSBY_SEND_MESSAGE_URL"] as string, {
                    method: "POST",
                    mode: "cors",
                    body: JSON.stringify({
                      ...values,
                      departureDate,
                      arrivalDate,
                    }),
                  })
                    .then(res => {
                      if (res.status === 200) {
                        sendTagManagerEvent({
                          event: "editaRichieste Preventivo",
                          eventCategory: "Richieste Preventivo",
                          eventAction: "Submit",
                          eventLabel: window?.location.href,
                          eventValue: "10",
                          eventNhi: "FALSE",
                          eventFacebook: "Richieste Preventivo Submit",
                        })

                        setSubmitting(false)
                        setFormSuccessMessage(submitOkMessage)
                        window.scrollTo(0, 0)
                      } else {
                        handleError()
                      }
                    })
                    .catch(() => {
                      handleError()
                    })
                }}
              >
                {({ values, isSubmitting, setFieldValue, errors, touched }) => {
                  return (
                    <Form className="my-5 enquiry-form">
                      <h4 className="mt-5 pb-3">{yourHoliday}</h4>
                      {/* ARRIVO E PARTENZA */}
                      <Row className="form-row">
                        <Col md="8">
                          <FieldDateRange
                            label={useTranslations("when")}
                            nameStart="arrivalDate"
                            nameEnd="departureDate"
                            minDate={openingDate}
                            maxDate={closingDate}
                          />
                        </Col>
                      </Row>
                      <Row className="form-row">
                        <Col md="4">
                          <FieldText type="select" name="accommodation" options={accommodationOptions} />
                        </Col>
                        <Col md="4">
                          <FieldText type="select" name="treatment" options={treatmentOptions} />
                        </Col>
                        <Col md="2">
                          <FieldText
                            type="select"
                            name="numberOfAdults"
                            options={[...Array(8).keys()].map((_, index) => ({
                              value: `${index + 1}`,
                              label: `${index + 1}`,
                            }))}
                            noTranslation={true}
                          />
                        </Col>
                        <Col md="2">
                          <FieldText
                            type="select"
                            name="numberOfChildren"
                            options={[...Array(8).keys()].map((_, index) => ({
                              value: `${index + 1}`,
                              label: `${index + 1}`,
                            }))}
                            noTranslation={true}
                          />
                        </Col>
                        <Col md="12">
                          <FieldArray
                            name="ageChildren"
                            render={() =>
                              values.ageChildren && values.ageChildren.length
                                ? values.ageChildren.map((_, index) => (
                                    <Row key={`age_${index}`}>
                                      <Col md="4">
                                        <FieldText name={`ageChildren.${index}` as FormFieldName} />
                                      </Col>
                                    </Row>
                                  ))
                                : null
                            }
                          />
                        </Col>
                      </Row>
                      <h4 className="mt-5 pb-3">{yourData}</h4>
                      {/* NOME E COGNOME */}
                      <Row className="form-row">
                        <Col md="4">
                          <FieldText name="firstName" />
                        </Col>
                        <Col md="4">
                          <FieldText name="lastName" />
                        </Col>
                        <Col md="4">
                          <FieldText type="email" name="email" />
                        </Col>
                      </Row>
                      <p className="mb-4">
                        <a href="" id="extra" className="btn-link small">
                          {extraInfo}
                        </a>
                      </p>
                      <UncontrolledCollapse toggler="#extra">
                        <Row className="form-row">
                          <Col md="4">
                            <FieldText name="address" />
                          </Col>
                          <Col md="2">
                            <FieldText name="postalCode" />
                          </Col>
                          <Col md="4">
                            <FieldText name="city" />
                          </Col>
                          <Col md="2">
                            <FieldText name="province" />
                          </Col>
                        </Row>
                        <Row className="form-row">
                          <Col md="12">
                            <FieldText type="textarea" name="extra_notes" />
                          </Col>
                        </Row>
                      </UncontrolledCollapse>
                      {/* TIPO DI RICHIESTA */}
                      <hr />
                      <Row className="form-row">
                        <Col md="6">
                          <FieldText type="select" name="how_do_you_know_us" options={howOptions} />
                        </Col>
                      </Row>
                      <hr />
                      <FormGroup tag="fieldset">
                        <Label>{requestType}</Label>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="radio"
                              name="requestType"
                              checked={values.requestType === "offer"}
                              onChange={() => setFieldValue("requestType", "offer")}
                            />{" "}
                            {offer}
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="radio"
                              name="requestType"
                              checked={values.requestType === "booking"}
                              onChange={() => setFieldValue("requestType", "booking")}
                            />{" "}
                            {booking}
                          </Label>
                        </FormGroup>
                      </FormGroup>
                      <hr />
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={values.privacyAccepted}
                            invalid={Boolean(touched.privacyAccepted && errors.privacyAccepted)}
                            onChange={() => setFieldValue("privacyAccepted", !values.privacyAccepted)}
                          />{" "}
                          <strong
                            className={`d-block ${
                              Boolean(touched.privacyAccepted && errors.privacyAccepted) ? "text-danger" : ""
                            }`}
                          >
                            {privacyTop}
                          </strong>
                          {privacyBottom}
                          {touched.privacyAccepted && errors.privacyAccepted ? (
                            <FormFeedback>{requiredErrorMessage}</FormFeedback>
                          ) : null}
                        </Label>
                      </FormGroup>
                      {/* <p className="text-notes py-3">
                        <strong className="d-block">{privacyTop}</strong>
                        {privacyBottom}
                      </p> */}
                      <hr />
                      {formErrorMessage && <Alert color="danger">{formErrorMessage}</Alert>}
                      <div className="pt-3 text-md-right">
                        <Button type="submit" disabled={isSubmitting}>
                          {submitText}
                        </Button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}
