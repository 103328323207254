import React from "react"
import { Field, FieldProps } from "formik"
import { FormGroup, Label, Input, FormFeedback } from "src/ui"
import useTranslations from "src/components/useTranslations"
import { FormFieldName, isRequiredField, FormState } from "./utils"

interface Props {
  name: FormFieldName
  type?: "email" | "text" | "select" | "textarea"
  options?: { value: string; label: string }[]
  noTranslation?: boolean
}

export const FieldText: React.FC<Props> = ({ name, type = "text", options, noTranslation = false }) => {
  const emptySelectOption = useTranslations("empty_select_option")
  const label = useTranslations(name.replace(/\./g, "_"))
  const isRequired = isRequiredField(name)
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps<string | undefined, FormState>) => {
        const hasError = Boolean(meta.touched && meta.error)
        return (
          <FormGroup>
            <Label for={name}>
              {label} {isRequired ? " *" : ""}
            </Label>
            <Input
              type={type}
              id={name}
              {...field}
              invalid={hasError}
              onChange={e => {
                field.onChange(e)
                if (field.name === "numberOfChildren") {
                  const childrenTotals = parseInt(e.currentTarget.value || "0")
                  const childrenTotalsFieldValues = childrenTotals > 0 ? Array(childrenTotals).fill("") : []
                  form.setFieldValue("ageChildren", childrenTotalsFieldValues)
                }
              }}
              children={
                type === "select" && options && options.length ? (
                  <React.Fragment>
                    <option>{emptySelectOption}</option>
                    {options.map((opt, index) => (
                      <option key={index} value={opt.value}>
                        {noTranslation ? opt.label : useTranslations(opt.label.replace(" ", ""))}
                      </option>
                    ))}
                  </React.Fragment>
                ) : (
                  undefined
                )
              }
            />
            {hasError ? <FormFeedback>{meta.error}</FormFeedback> : null}
          </FormGroup>
        )
      }}
    </Field>
  )
}
